import { loadMore } from "./utils";

const blockListaTeam = () => {
  const listaTeamSections = document.querySelectorAll(".lista-team__section");
  if (listaTeamSections.length > 0) {
    listaTeamSections.forEach((listaTeamSection) => {
      const loadMoreBtn = listaTeamSection.querySelector(".load-more__team");
      if (loadMoreBtn) {
        loadMoreBtn.addEventListener("click", () => {
          loadMore(loadMoreBtn, document.querySelectorAll(".lista-team__item"));
        });
      }
    });
  }
};

export default blockListaTeam;
