/* Bootstrap  */
// import "bootstrap";
// import { Modal } from 'bootstrap'
import "slick-carousel";

/* Custom SCSS */
import "./index.scss";

/* SwiperJS */
// import Swiper /*, { Navigation, Pagination, Scrollbar }*/ from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

import initMap from "./js/initMap";

import navbar from "./js/navbar";
import blockSliderNews from "./js/blockSliderNews";
import blockEmbedVideo from "./js/blockEmbedVideo";
import blockListaTeam from "./js/blockListaTeam";
import blockAccordion from "./js/blockAccordion";
import cardGallery from "./js/cardGallery";
import filterNews from "./js/filterNews";
import sectionModal from "./js/sectionModal";
import btnWhatsapp from "./js/btnWhatsapp";
import sectionsFadeIn from "./js/sectionsFadeIn";

window.initMap = initMap;

navbar();
blockSliderNews();
blockEmbedVideo();
blockListaTeam();
blockAccordion();
cardGallery();
filterNews();
sectionModal();
btnWhatsapp();
sectionsFadeIn();
