const cardGallery = () => {
  $(() => {
    if ($(".card__gallery .wp-block-group__inner-container")) {
      const nav = document.querySelector(".card__gallery__nav");
      $(".card__gallery .wp-block-group__inner-container").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
        appendArrows: nav,
        rows: 0,
      });

      const slide = document.querySelector(".card__gallery .slick-slide");
      if (slide) {
        changeWidth(slide, nav);
        window.addEventListener("resize", () => {
          changeWidth(slide, nav);
        });
      }
    }
  });
};

export default cardGallery;

const changeWidth = (original, clone) => {
  const originalWidth = original.getBoundingClientRect().width;
  clone.style.width = `${originalWidth}px`;
};
