/**
 * Load more Buton
 *
 * @param {*} loadMoreBtn
 * @param {*} items
 */

export const loadMore = (loadMoreBtn, items) => {
  let currentPage = parseInt(loadMoreBtn.getAttribute("data-current"));
  const totalPages = getTotalPages(items);
  if (currentPage < totalPages) {
    currentPage++;
    items.forEach((item) => {
      const item_page = parseInt(item.getAttribute("data-page"));
      if (item_page <= currentPage && item.classList.contains("d-none")) {
        item.classList.remove("d-none");
      }
    });
    if (currentPage == totalPages) {
      loadMoreBtn.classList.add("d-none");
    }
    loadMoreBtn.setAttribute("data-current", currentPage);
  }
};

const getTotalPages = (items) => {
  let totalPages = 1;
  items.forEach((item) => {
    const attr = parseInt(item.getAttribute("data-page"));
    totalPages = totalPages < attr ? attr : totalPages;
  });

  return totalPages;
};
