const navbar = () => {
  $(() => {
    // Toggler
    const toggler = document.querySelector(".toggler_wrapper");
    const offcanvas = document.getElementById("navbarToggler");
    if (toggler && offcanvas) {
      toggler.addEventListener("click", () => {
        toggler.querySelector(".navbar-toggler").classList.toggle("collapsed");
        offcanvas.classList.toggle("aperto");
        document.body.classList.toggle("noscroll");
      });
    }
  });

  // Dropdown
  const dropdowns = document.querySelectorAll("#menu-main-menu .menu-item-has-children");
  if (dropdowns.length > 0) {
    dropdowns.forEach((dropdown) => {
      const toggler = dropdown.querySelector("a");
      const subMenu = dropdown.querySelector(".sub-menu");

      if (toggler && subMenu) {
        toggler.addEventListener("click", () => {
          dropdown.classList.toggle("open");
        });
      }
    });
  }

  // Scroll
  const navbar = document.querySelector(".navbar");
  if (navbar) {
    toggleScrolledClass(navbar)
    window.addEventListener("scroll", () => {
      toggleScrolledClass(navbar)
    });
  }
};

export default navbar;

const toggleScrolledClass = (navbar) => {
  if (window.scrollY > 10) {
    navbar.classList.add("scrolled");
  } else {
    navbar.classList.remove("scrolled");
  }
};
