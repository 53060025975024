const sectionsFadeIn = () => {
  /************************************************************** */
  /**** Check if element with class 'fadeOnScreen' is in viewport */
  /************************************************************** */

  /********************* 1° Function for check if element is in viewport */

  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  /********************* 2° Call function on load */

  checkonScreen();

  /********************* 3° Call function on scroll */

  window.addEventListener("scroll", () => {
    checkonScreen();
    if ($(window).scrollTop() + $(window).height() == $(document).height()) {
      let elementsinviewport = [];
    } else if ($(window).scrollTop() == 0) {
      let elementsinviewport = [];
    }

    /* faccio un check sulla posizione del menu e assegno una classe */
    if ($(this).scrollTop() > 50) {
      $(".navbar").addClass("navbar_active");
    } else {
      $(".navbar").removeClass("navbar_active");
    }
  });

  function checkonScreen() {
    $(".fadeOnScreen").each(function (index) {
      if ($(this).isInViewport()) {
        $(this).addClass("fadeOnScreen_active");
      } else {
        $(this).removeClass("fadeOnScreen_active");
      }
    });
  }
};

export default sectionsFadeIn;
