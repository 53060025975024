const sectionModal = () => {
  $(() => {
    const modals = document.querySelectorAll(".accordion__modal");
    if (modals.length > 0) {
      modals.forEach((modal) => {
        document.body.appendChild(modal);
      });
    }
  });
};

export default sectionModal;
