let map;

const initMap = async () => {
  try {
    if (typeof google !== "undefined" && google.maps) {
      const mapElement = document.getElementById("map");
      if (mapElement) {
        const { Map, InfoWindow } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        const marker = document.querySelector(".marker");

        // Get Main Pin LatLng
        if (marker) {
          const location = {
            lat: parseFloat(marker.getAttribute("data-lat")),
            lng: parseFloat(marker.getAttribute("data-lng")),
          };

          // Create Map
          const map = new Map(document.getElementById("map"), {
            center: location,
            zoom: 15,
            mapId: "4504f8b37375c3d0",
          });

          // Create Main Pin
          const mainPin = new AdvancedMarkerElement({
            map,
            position: location,
            title: "Generali Città dei Mille",
          });
        }
      }
    } else {
      console.error("Google Maps API is not loaded.");
    }
  } catch (error) {
    console.error("An error occurred during map initialization:", error);
  }
};

export default initMap;

