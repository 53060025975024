import videojs from "video.js";
import "videojs-youtube";

const blockEmbedVideo = () => {
  const videoSections = document.querySelectorAll(".embed-video__section");

  if (videoSections.length > 0) {
    videoSections.forEach((videoSection) => {

      const videoElement = videoSection.querySelector("video");
      const id = "#" + videoElement.id;
      const player = videojs(id, {
        autoplay: false,
        controls: true,
        width: "100%",
        // poster: videoElement.dataset.poster,
        preload: "none",
      });
      const linkvideo = document.querySelector(".embed-video__link.video");
      const linkvolume = document.querySelector(".embed-video__link.volume");
      const showLinks = () => {
        linkvideo.style.opacity = 1;
        linkvolume.style.opacity = 1;
      };
      const hideLinks = () => {
        linkvideo.style.opacity = 0;
        linkvolume.style.opacity = 0;
      };
      linkvideo.addEventListener("click", () => {
        linkvideo.classList.toggle("loading");
        if (linkvideo.classList.contains("loading")) {
          const videoJsElement = videoSection.querySelector(".video-js");
          videoJsElement.style.display = "block";
          linkvolume.style.display = "block";
          hideLinks();
          player.play();
          videoSection.addEventListener("mouseenter", showLinks);
          videoSection.addEventListener("touchstart", showLinks);
          videoSection.addEventListener("mouseleave", hideLinks);
          videoSection.addEventListener("touchend", hideLinks);
        } else {
          showLinks();
          player.pause();
        }
        player.on("ended", () => {
          const videoJsElement = videoSection.querySelector(".video-js");
          videoJsElement.style.display = "none";
        });
      });
      linkvolume.addEventListener("click", () => {
        linkvolume.classList.toggle("loading");
        if (linkvolume.classList.contains("loading")) {
          player.muted(true);
        } else {
          player.muted(false);
        }
      });
    });
  }
};

export default blockEmbedVideo;
