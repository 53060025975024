const blockSliderNews = () => {
  $(() => {
    if ($(".slider-news__list")) {
      $(".slider-news__list").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: document.querySelector(".slider-news__nav.next"),
        prevArrow: document.querySelector(".slider-news__nav.prev"),
        rows: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }
  });
};

export default blockSliderNews;
