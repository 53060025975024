import { loadMore } from './utils.js'

const filterNews = () => {
  const filterSection = document.querySelector(".news__filter__section");
  if (filterSection) {
    // Init data
    let data = {
      action: "filter_news",
      security: ajax_auth_object.ajax_nonce,
    };

    // Category Select
    const catSelect = document.getElementById("news__filter__cat-select");
    if (catSelect) {
      catSelect.addEventListener("change", () => {
        data.cat = catSelect.value;
      });
    }

    // Order by Select
    const orderbySelect = document.getElementById("news__filter__orderby-select");
    if (orderbySelect) {
      orderbySelect.addEventListener("change", () => {
        data.orderby = orderbySelect.value;
      });
    }

    // Order Button
    const orderBtn = document.getElementById("news__filter__order");
    if (orderBtn) {
      orderBtn.addEventListener("click", () => {
        orderBtn.classList.toggle("desc");
        data.order = orderBtn.classList.contains("desc") ? "DESC" : "ASC";
      });
    }

    // Submit Button
    const filterBtn = document.getElementById("news__filter__submit");
    if (filterBtn) {
      filterBtn.addEventListener("click", () => {
        // Ajax Call
        ajaxCall(data);
      });
    }

    // Load More Button
    const loadMoreBtn = document.getElementById("load-more__news");
    if (loadMoreBtn) {
      loadMoreBtn.addEventListener("click", () => {
        loadMore(loadMoreBtn, document.querySelectorAll(".slider-news__item"));
      });
    }
  }
};

export default filterNews;

/**
 * Ajax Call
 *
 * @param {*} data
 */

const ajaxCall = (data) => {
  $.post({
    url: ajax_auth_object.ajax_url,
    data: data,
    dataType: "json",
    success: (res) => {
      removeAllItems();
      if (res.data === "empty") {
        console.log("Ajax call had no results.");
      } else {
        listAllItems(res.data);
        console.log("Ajax call had " + res.data.length + " results.");
      }
    },
    error: (e) => {
      console.log("Error " + e.status + ": " + e.statusText);
      console.log(e.responseText);
    },
  });
};

/**
 * Remove All News from Results
 */

const removeAllItems = () => {
  const items = document.querySelectorAll(".slider-news__item");
  items.forEach((item) => {
    item.remove();
  });
};

/**
 * List All Filtered News
 *
 * @param {*} data
 */

const listAllItems = (data) => {
  const newsList = document.getElementById("news__list");
  let page = null;
  if (newsList) {
    data.forEach((item) => {
      page = item.page;
      let single = `
        <div class="col-12 col-md-4 slider-news__item pb-2 pb-md-4" data-cat-id="${item.cat.id}" data-page="${item.page}">
          <a href="${item.link}" class="slider-news__link-img">
            ${item.thumbnail ? `<img src="${item.thumbnail}" alt="${item.title}" class="slider-news__img">` : `<div class="slider-news__placeholder"></div>`}
          </a>
          <div class="slider-news__cat">
            ${item.cat.name}
          </div>
          <a href="${item.link}" class="slider-news__link-title">
            <h3 class="slider-news__title">
              ${item.title}
            </h3>
          </a>
          <div class="slider-news__date">
            ${item.date}
          </div>
        </div>
      `;
      newsList.insertAdjacentHTML("beforeend", single);
    });

    console.log(page)

    const loadMoreBtn = document.getElementById("load-more__news");
    if (loadMoreBtn) {
      if (page > 1) {
        loadMoreBtn.classList.remove("d-none");
      } else {
        loadMoreBtn.classList.add("d-none");
      }
    }
  }
};
