import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

const btnWhatsapp = () => {
  document.addEventListener("DOMContentLoaded", () => {
    let start = setStartPoint();

    const debounce = (func, delay) => {
      let timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(func, delay);
      };
    };

    const updateStartPoint = debounce(() => {
      start = setStartPoint();
      ScrollTrigger.refresh();
    }, 250);

    window.addEventListener("resize", updateStartPoint);

    ScrollTrigger.create({
      trigger: "#footer",
      start: () => start + " bottom",
      end: "bottom bottom",
      markers: false,
      onEnter: () => document.querySelector(".whatsapp-btn").classList.add("locked"),
      onLeaveBack: () => document.querySelector(".whatsapp-btn").classList.remove("locked"),
    });
  });
};

export default btnWhatsapp;

const setStartPoint = () => {
  return window.innerWidth > 576 ? "top" : "150px";
};
