const blockAccordion = () => {
  const hash = window.location.hash,
    offset = window.innerWidth > 768 ? 250 : 180;
  if (hash) {
    window.onload = () => {
      const accordion = document.querySelector(hash);
      const btn = document.querySelector('.accordion__btn[data-bs-target="' + hash + '"]');
      if (accordion && btn) {
        accordion.classList.add("show");
        btn.classList.remove("collapsed");
        btn.setAttribute("aria-expanded", "true");
        setTimeout(() => {
          window.scrollTo({
            top: accordion.getBoundingClientRect().top + window.scrollY - offset,
            behavior: "smooth",
          });
        }, 200);
      }
    };
  }

};

export default blockAccordion;
